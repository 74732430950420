<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <BoxView title="BoxWidget" :grid="100">
        <GroupBoxWidget>
          <!-- default -->
          <BoxWidget
            title="Prospective"
            :grid="25"
            :value="2493"/>
          <BoxWidget
            title="Free"
            :grid="25"
            :value="1250"/>
          <BoxWidget
            title="Ragular Payment"
            :grid="25"
            :value="30281"/>
          <BoxWidget
            title="total"
            :grid="25"
            :value="9999999"/>
          <!-- boxBgColor -->
          <BoxWidget
            title="Prospective"
            boxBgColor="#6CDFC3"
            :grid="25"
            :value="2493"/>
          <BoxWidget
            title="Free"
            boxBgColor="#86BEFF"
            :grid="25"
            :value="1250"/>
          <BoxWidget
            title="Ragular Payment"
            boxBgColor="#A77CFF"
            :grid="25"
            :value="30281"/>
          <BoxWidget
            title="total"
            boxBgColor="#FFD82A"
            :grid="25"
            :value="9999999"/>
        </GroupBoxWidget>
      </BoxView>
      <BoxView title="BoxWidget -with icon" :grid="100">
        <GroupBoxWidget>
          <!-- iconColor -->
          <BoxWidget
            iconName="preview"
            title="Prospective"
            iconColor="#6CDFC3"
            :grid="25"
            :value="2493"/>
          <BoxWidget
            iconName="email"
            title="Free"
            iconColor="#86BEFF"
            :grid="25"
            :value="1250"/>
          <BoxWidget
            iconName="phone"
            title="Ragular Payment"
            iconColor="#A77CFF"
            :grid="25"
            :value="30281"/>
          <BoxWidget
            iconName="logo"
            title="total"
            iconColor="#FFD82A"
            :grid="25"
            :value="9999999"/>
          <!-- iconBgColor -->
          <BoxWidget
            iconName="preview"
            iconBgColor="#6CDFC3"
            title="Prospective"
            :grid="25"
            :value="2493"/>
          <BoxWidget
            iconName="email"
            iconBgColor="#86BEFF"
            title="Free"
            :grid="25"
            :value="1250"/>
          <BoxWidget
            iconName="phone"
            iconBgColor="#A77CFF"
            title="Ragular Payment"
            :grid="25"
            :value="30281"/>
          <BoxWidget
            iconName="logo"
            iconBgColor="#FFD82A"
            title="total"
            :grid="25"
            :value="9999999"/>
          <!-- iconBgColor / boxBgColor -->
          <BoxWidget
            iconName="preview"
            iconBgColor="#6CDFC3"
            title="Prospective"
            boxBgColor="#6CDFC3"
            :grid="25"
            :value="2493"/>
          <BoxWidget
            iconName="email"
            iconBgColor="#86BEFF"
            title="Free"
            boxBgColor="#86BEFF"
            :grid="25"
            :value="1250"/>
          <BoxWidget
            iconName="phone"
            iconBgColor="#A77CFF"
            title="Ragular Payment"
            boxBgColor="#A77CFF"
            :grid="25"
            :value="30281"/>
          <BoxWidget
            iconName="logo"
            iconBgColor="#FFD82A"
            title="total"
            boxBgColor="#FFD82A"
            :grid="25"
            :value="9999999"/>
        </GroupBoxWidget>
      </BoxView>
      <BoxView title="BoxWidgetProgress" :grid="100">
        <GroupBoxWidget>
          <!-- default -->
          <BoxWidget
            title="Prospective"
            :isProgress="true"
            :grid="25"
            :value="2493"
            :totalValue="10000"/>
          <BoxWidget
            title="Free"
            :isProgress="true"
            :grid="25"
            :value="1250"
            :totalValue="10000"/>
          <BoxWidget
            title="Ragular Payment"
            :isProgress="true"
            :grid="25"
            :value="30281"
            :totalValue="10000"/>
          <BoxWidget
            title="total"
            :isProgress="true"
            :grid="25"
            :value="5999999"
            :totalValue="9999999"/>
          <!-- boxBgColor / isShowValue:false / isShowPercent:true -->
          <BoxWidget
            title="Prospective"
            :isProgress="true"
            :grid="25"
            :value="2493"
            :totalValue="10000"
            boxBgColor="#6CDFC3"
            :isShowValue="false"
            :isShowPercent="true"/>
          <BoxWidget
            title="Free"
            :isProgress="true"
            :grid="25"
            :value="1250"
            :totalValue="10000"
            boxBgColor="#86BEFF"
            :isShowValue="false"
            :isShowPercent="true"/>
          <BoxWidget
            title="Ragular Payment"
            :isProgress="true"
            :grid="25"
            :value="30281"
            :totalValue="10000"
            boxBgColor="#A77CFF"
            :isShowValue="false"
            :isShowPercent="true"/>
          <BoxWidget
            title="total"
            :isProgress="true"
            :grid="25"
            :value="5999999"
            :totalValue="9999999"
            boxBgColor="#FFD82A"
            :isShowValue="false"
            :isShowPercent="true"/>
        </GroupBoxWidget>
      </BoxView>
      <BoxView title="BoxWidgetProgress -with icon" :grid="100">
        <GroupBoxWidget>
          <!-- iconColor / isShowTotal:true -->
          <BoxWidget
            iconName="preview"
            :isProgress="true"
            title="Prospective"
            iconColor="#6CDFC3"
            :grid="25"
            :value="2493"
            :totalValue="10000"
            :isShowTotal="true"/>
          <BoxWidget
            iconName="email"
            :isProgress="true"
            title="Free"
            iconColor="#86BEFF"
            :grid="25"
            :value="1250"
            :totalValue="10000"
            :isShowTotal="true"/>
          <BoxWidget
            iconName="phone"
            :isProgress="true"
            title="Ragular Payment"
            iconColor="#A77CFF"
            :grid="25"
            :value="30281"
            :totalValue="10000"
            :isShowTotal="true"/>
          <BoxWidget
            iconName="logo"
            :isProgress="true"
            title="total"
            iconColor="#FFD82A"
            :grid="25"
            :value="5999999"
            :totalValue="9999999"
            :isShowTotal="true"/>
          <!-- iconBgColor / isShowPercent:true -->
          <BoxWidget
            iconName="preview"
            :isProgress="true"
            iconBgColor="#6CDFC3"
            title="Prospective"
            :grid="25"
            :value="2493"
            :totalValue="10000"
            :isShowPercent="true"/>
          <BoxWidget
            iconName="email"
            :isProgress="true"
            iconBgColor="#86BEFF"
            title="Free"
            :grid="25"
            :value="1250"
            :totalValue="10000"
            :isShowPercent="true"/>
          <BoxWidget
            iconName="phone"
            :isProgress="true"
            iconBgColor="#A77CFF"
            title="Ragular Payment"
            :grid="25"
            :value="30281"
            :totalValue="10000"
            :isShowPercent="true"/>
          <BoxWidget
            iconName="logo"
            :isProgress="true"
            iconBgColor="#FFD82A"
            title="total"
            :grid="25"
            :value="5999999"
            :totalValue="9999999"
            :isShowPercent="true"/>
          <!-- iconBgColor / boxBgColor / isShowTotal:true / isShowPercent:true -->
          <BoxWidget
            iconName="preview"
            :isProgress="true"
            iconBgColor="#6CDFC3"
            title="Prospective"
            boxBgColor="#6CDFC3"
            :grid="25"
            :value="2493"
            :totalValue="10000"
            :isShowTotal="true"
            :isShowPercent="true"/>
          <BoxWidget
            iconName="email"
            :isProgress="true"
            iconBgColor="#86BEFF"
            title="Free"
            boxBgColor="#86BEFF"
            :grid="25"
            :value="1250"
            :totalValue="10000"
            :isShowTotal="true"
            :isShowPercent="true"/>
          <BoxWidget
            iconName="phone"
            :isProgress="true"
            iconBgColor="#A77CFF"
            title="Ragular Payment"
            boxBgColor="#A77CFF"
            :grid="25"
            :value="30281"
            :totalValue="10000"
            :isShowTotal="true"
            :isShowPercent="true"/>
          <BoxWidget
            iconName="logo"
            :isProgress="true"
            iconBgColor="#FFD82A"
            title="total"
            boxBgColor="#FFD82A"
            :grid="25"
            :value="5999999"
            :totalValue="9999999"
            :isShowTotal="true"
            :isShowPercent="true"/>
        </GroupBoxWidget>
      </BoxView>
      <BoxView title="BoxInfo" :grid="100">
        <GroupBoxWidget>
          <BoxInfo
            title="title"
            :grid="20"
            :isActive="true"
            value="active"
            emptyValueText="⚠️ empty"/>
          <BoxInfo
            title="title"
            :grid="20"
            :isActive="false"
            value="value2"
            emptyValueText="⚠️ empty"/>
          <BoxInfo
            title="title"
            :grid="20"
            :isActive="false"
            value="value3"
            emptyValueText="⚠️ empty"/>
          <BoxInfo
            title="title"
            :grid="20"
            :isActive="false"
            value=""
            emptyValueText="⚠️ empty"/>
          <BoxInfo
            title="title"
            :grid="20"
            :isActive="false"
            value=""
            emptyValueText="⚠️ empty"/>
        </GroupBoxWidget>
      </BoxView>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import GroupBoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/GroupBoxWidget';
import BoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/BoxWidget';
import BoxInfo from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/BoxInfo';

export default {
  name:'Widgets',
  components:{
    PageWithLayout,
    BoxView,
    GroupBoxWidget,
    BoxWidget,
    BoxInfo
  },
}
</script>

<style scoped>
</style>